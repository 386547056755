import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';

import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { layout: 'LoginLayout' }
  },
  {
    path: '/ivx-annotate',
    name: 'IvxAnnotate',
    component: () => import('../views/Annotate.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/fas-annotate',
    name: 'FasAnnotate',
    component: () => import('../views/FasAnnotate.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/fas-annotate/real',
    name: 'FasAnnotateReal',
    component: () => import('../views/FasAnnotate.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/fas-annotate/fake',
    name: 'FasAnnotateFake',
    component: () => import('../views/FasAnnotate.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/result',
    name: 'Result',
    component: () => import('../views/Result.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/review/:reviewId',
    name: 'Review',
    props: true,
    component: () => import('../views/Review.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/User.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/annotate/depth',
    name: 'AnnotateDepth',
    component: () => import('../views/annotate/AnnotateDepth.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/annotate/quality',
    name: 'AnnotateDepthQuality',
    component: () => import('../views/annotate/AnnotateDepthQuality.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/annotate/spoof-quality',
    name: 'AnnotateSpoofQuality',
    component: () => import('../views/annotate/AnnotateSpoofQuality.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/annotate/quality-check',
    name: 'AnnotateQualityCheck',
    component: () => import('../views/annotate/AnnotateQualityCheck.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/annotate/real-quality',
    name: 'AnnotateRealQuality',
    component: () => import('../views/annotate/AnnotateRealQuality.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/annotate/review-quality',
    name: 'AnnotateReviewQuality',
    component: () => import('../views/annotate/AnnotateReviewQuality.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/annotate/review-batch7',
    name: 'AnnotateReviewBatch5',
    component: () => import('../views/annotate/AnnotateReviewBatch7.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/annotate/review-batch6',
    name: 'AnnotateReviewBatch6',
    component: () => import('../views/annotate/AnnotateReviewBatch6.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/practice/depth',
    name: 'PracticeDepth',
    component: () => import('../views/practice/PracticeDepth.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/practice/quality',
    name: 'PracticeQuality',
    component: () => import('../views/practice/PracticeQuality.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/practice/review',
    name: 'PracticeReview',
    component: () => import('../views/practice/PracticeReview.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/annotator/stats',
    name: 'AnnotatorStats',
    component: () => import('../views/AnnotatorStats.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/annotate/match',
    name: 'AnnotateMatch',
    component: () => import('../views/annotate/AnnotateMatch.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/managers-review',
    name: 'ManagersReview',
    component: () => import('../views/ManagersReview.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/trailer-frame',
    name: 'TrailerFrame',
    component: () => import('../views/TrailerFrame.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/movie-frame',
    name: 'MovieFrame',
    component: () => import('../views/MovieFrame.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/bbox-movie',
    name: 'BBoxLabeling',
    component: () => import('../views/bbox/BBoxMovie.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/bbox-poster',
    name: 'BBoxPoster',
    component: () => import('../views/bbox/BBoxPoster.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/bbox-trailer',
    name: 'BBoxTrailer',
    component: () => import('../views/bbox/BBoxTrailer.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/bbox-batch7',
    name: 'BBoxBatch7',
    component: () => import('../views/bbox/BBoxBatch7.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/celebrities-review',
    name: 'CelebritiesReview',
    component: () => import('../views/celebrity/CelebritiesReview.vue'),
    meta: { layout: 'AppLayout' }
  },
  {
    path: '/bbox-management-tool',
    name: 'ManagementTool',
    component: () => import('../views/bbox/ManagementTool.vue'),
    meta: { layout: 'AppLayout' }
  }

]

const router = createRouter({
  linkActiveClass: 'active',
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/') return next()

  document.querySelector('body').className = ''

  const isLoggedIn = store.getters['auth/isLoggedIn'];
  const isManager = store.getters['auth/isManager'];
  const managerRouters = ['Users']

  // Unauthenticated user can only access the home page.
  if (!isLoggedIn) {
    next('/');
  } else if (managerRouters.includes(to.name) && !isManager) {
    next('/');
  } else {
    next();
  }
});

export default router
